<template>
  <component is="b-card">
    <b-tabs pills>
      <!-- Tab: Received -->
      <b-tab active @click="receivedClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Received") }}</span>
        </template>
        <order-received-tab
          ref="receivedRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
          :loading ="loading"
        />
      </b-tab>
      <b-tab @click="inPreparationClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("In preparation") }}</span>
        </template>
        <order-in-preparation-tab
          ref="inPreparationRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="readyClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready") }}</span>
        </template>
        <order-ready-tab
          ref="readyRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="readyInStockClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready in stock") }}</span>
        </template>
        <order-ready-in-stock-tab
          ref="readyInStockRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <!--<b-tab @click="verifiedClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Ready to delivery") }}</span>
        </template>
        <order-verified-tab
          ref="verifiedRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>-->
      <b-tab @click="LoadedClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Loaded") }}</span>
        </template>
        <order-Loaded-tab
          ref="LoadedRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="DeliveredClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Delivered") }}</span>
        </template>
        <order-Delivered-tab
          ref="DeliveredRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="NotDeliveredClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Not Delivered") }}</span>
        </template>
        <order-NotDelivered-tab
          ref="NotDeliveredRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="ReturnClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Return") }}</span>
        </template>
        <order-Return-tab
          ref="ReturnRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="ValidReturnClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Valid Return") }}</span>
        </template>
        <order-ValidReturn-tab
          ref="ValidReturnRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="canceledClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Canceled") }}</span>
        </template>
        <order-canceled-tab
          ref="canceledRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
      <b-tab @click="UnavailableClicked">
        <template #title>
          <span class="d-none d-sm-inline">{{ $t("Unavailable") }}</span>
        </template>
        <unavailable-tab
          ref="UnavailableRef"
          class="mt-2 pt-75"
          :sub-profiles="subProfiles"
          :emergency-degrees="emergencyDegrees"
          :clients="clients"
        />
      </b-tab>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import OrderReceivedTab from "./OrderReceivedTab.vue";
import OrderToPrepareTab from "./OrderToPrepareTab.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import OrderInPreparationTab from "./OrderInPreparationTab.vue";
import OrderReadyTab from "./OrderReadyTab.vue";
import OrderReadyToCheckTab from "./OrderReadyToCheckTab.vue";
import OrderReadyInStockTab from "./OrderReadyInStockTab.vue";
import OrderVerifiedTab from "./OrderVerifiedTab.vue";
import OrderCanceledTab from "./OrderCanceledTab.vue";
import OrderLoadedTab from "./OrderLoadedTab.vue";
import OrderDeliveredTab from "./OrderDeliveredTab.vue";
import OrderNotDeliveredTab from "./OrderNotDeliveredTab.vue";
import OrderReturnTab from "./OrderReturnTab.vue";
import OrderValidReturnTab from "./OrderValidReturnTab.vue";
import UnavailableTab from "./UnavailableTab.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OrderReceivedTab,
    OrderToPrepareTab,
    OrderInPreparationTab,
    OrderReadyTab,
    OrderReadyToCheckTab,
    OrderReadyInStockTab,
    OrderVerifiedTab,
    OrderCanceledTab,
    OrderLoadedTab,
    OrderDeliveredTab,
    OrderNotDeliveredTab,
    OrderReturnTab,
    OrderValidReturnTab,
    UnavailableTab,
  },
  data() {
    return {
      subProfiles: [],
      emergencyDegrees: [],
      clients: [],
      loading:null,
    };
  },
  methods: {
    receivedClicked() {
      this.$refs["receivedRef"].refreshTable();
    },
    toPrepareClicked() {
      this.$refs["toPrepareRef"].refreshTable();
    },
    inPreparationClicked() {
      this.$refs["inPreparationRef"].refreshTable();
    },
    readytoCheckClicked() {
      this.$refs["readyToCheckRef"].refreshTable();
    },
    readyInStockClicked() {
      this.$refs["readyInStockRef"].refreshTable();
    },
    readyClicked() {
      this.$refs["readyRef"].refreshTable();
    },
    verifiedClicked() {
      this.$refs["verifiedRef"].refreshTable();
    },
    canceledClicked() {
      this.$refs["canceledRef"].refreshTable();
    },
    LoadedClicked() {
      this.$refs["LoadedRef"].refreshTable();
    },
    DeliveredClicked() {
      this.$refs["DeliveredRef"].refreshTable();
    },
    NotDeliveredClicked() {
      this.$refs["NotDeliveredRef"].refreshTable();
    },
    ValidReturnClicked() {
      this.$refs["ValidReturnRef"].refreshTable();
    },
    ReturnClicked() {
      this.$refs["ReturnRef"].refreshTable();
    },
    UnavailableClicked() {
      this.$refs["UnavailableRef"].refreshTable();
    },
  },
  async mounted() {
    try {
      this.loading=true;
      const res = await Promise.all([
        instance.get("/parameters/subprofiles/"),
        instance.get("/parameters/emergency-degrees/"),
        instance.get("/parameters/clients/"),
      ]);
      this.subProfiles = res[0].data;
      this.emergencyDegrees = res[1].data;
      this.clients = res[2].data;
    } catch (err) {
      const error = err.response
        ? Object.values(err.response.data)[0][0]
        : err.message;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: error ?? this.$t("An error has occurred. Please try again"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style></style>
