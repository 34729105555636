<template>
  <!----<div v-if="stateId === 'ready_to_delivery'">
    <b-modal
      :ref="'consulte-order-' + data.item.id"
      centered
      size="xl"
      @hidden="onConsulteOrderModalHidden"
      :title="$t('Consultation')"
      hide-footer
    >
      <b-table
        select-mode="multi"
        @row-selected="onRowSelected"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="orderLines"
        :fields="fields"
        responsive
      >
        <template #cell(actions)="data">
          <template>
            <feather-icon
              @click="onRowSelected(data.item)"
              icon="Edit2Icon"
              size="16"
              class="align-middle text-body"
            />
          </template>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="delivery-info"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      @hidden="onDeliveryInfoModalHidden"
      @ok="confirmOrderLineConsultation"
      size="lg"
      :title="$t('Change delivery info')"
    >
      <b-form>
        <b-row>
          <b-col cols="4">
            <b-form-group
              label-for="delivery-method"
              :label="$t('Shipping type')"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deliveryTypes"
                v-model="selectedShippingType"
                input-id="delivery-method"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="4">
            <b-form-group
              :label="$t('Shipping date')"
              label-for="shipping_date"
            >
              <flat-pickr
                id="shipping_date"
                v-model="selectedShippingDate"
                :config="{ enableTime: true, dateFormat: 'Y-m-d H:i' }"
                class="form-control"
              />
            </b-form-group>
          </b-col>

          <b-col cols="4">
            <b-form-group label-for="delivery-man" :label="$t('Delivery Man')">
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deliveryMen"
                v-model="selectedDeliveryMan"
                input-id="delivery-man"
                label="name"
                state-id="verified"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>-->
  <div>
    <b-modal
      :ref="'consulte-order-' + data.item.id"
      centered
      size="xl"
      @hidden="onConsulteOrderModalHidden"
      :title="$t('Consultation')"
      hide-footer
    >
      <div class="flex_box">
        <table style="width: max-content !important">
          <tbody>
            <tr>
              <td class="color border padding_table">
                <strong>{{ $t("Client") }}</strong>
              </td>
              <td class="border padding_table">{{ client }}</td>
            </tr>
            <tr class="border">
              <td class="color border padding_table">
                <strong>{{ $t("Order source") }}</strong>
              </td>
              <td class="border padding_table">{{ orderSource }}</td>
            </tr>
          </tbody>
        </table>
        <table style="width: max-content !important">
          <tbody>
            <tr>
              <td class="color border padding_table">
                <strong>{{ $t("Order date") }}</strong>
              </td>
              <td class="border padding_table">{{ orderDate }}</td>
            </tr>
            <tr class="border">
              <td class="color border padding_table">
                <strong>{{ $t("Order add by") }}</strong>
              </td>
              <td class="border padding_table">{{ operator }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <b-table
        id="scrolle-table"
        select-mode="multi"
        @row-selected="onRowSelected"
        show-empty
        :empty-text="$t('No matching records found')"
        :items="orderLines"
        :fields="fields"
        responsive
      >
        <template #cell(actions)="data">
          <template>
            <feather-icon
              @click="onRowSelected(data.item)"
              icon="Edit2Icon"
              size="16"
              class="align-middle text-body"
            />
          </template>
        </template>
      </b-table>
    </b-modal>

    <b-modal
      ref="delivery-info"
      cancel-variant="outline-secondary"
      :ok-title="$t('Confirm')"
      :cancel-title="$t('Cancel')"
      centered
      @hidden="onDeliveryInfoModalHidden"
      @ok="confirmOrderLineConsultation"
      size="lg"
      :title="$t('Change delivery info')"
    >
      <b-form>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label-for="delivery-method"
              :label="$t('Shipping type')"
            >
              <v-select
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="deliveryTypes"
                v-model="selectedShippingType"
                input-id="delivery-method"
                label="name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              :label="$t('Shipping date')"
              label-for="shipping_date"
            >
              <flat-pickr
                id="shipping_date"
                v-model="selectedShippingDate"
                :config="{ enableTime: false }"
                class="form-control"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BFormInvalidFeedback,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    BFormInvalidFeedback,
  },
  props: {
    data: Object,
    stateId: String,
  },
  created() {
    this.loadDeliveryMan();
  },
  data() {
    return {
      //infos: [],
      orderLines: [],
      deliveryTypes: [],
      selectedOrderLine: null,
      selectedShippingDate: null,
      selectedShippingType: null,
      orderDate: null,
      client: null,
      orderSource: null,
      shippingType: null,
      shippingDate: null,
      operator: null,
      order: null,
      fields:
        this.stateId === "received"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "in_preparation"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "delivered"
          ? [  
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "not_delivered"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "return"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "valid_return"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "canceled"
          ? [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : this.stateId === "unavailable"
          ?[
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ]
          : [
              { key: "client_annexe", label: this.$t("client annexe") },
              { key: "product", label: this.$t("product") },
			  { key: "preparations", label: this.$t("preparation") },
              { key: "ordered_qty", label: this.$t("ordered qty") },
              { key: "piece", label: this.$t("ordered piece") },
              { key: "available_qty", label: this.$t("available qty") },
              { key: "available_piece", label: this.$t("available piece") },
              { key: "shipping_type", label: this.$t("shipping type") },
              { key: "shipping_date", label: this.$t("shipping date") },
              { key: "type", label: this.$t("order type") },
              { key: "emergency_degree", label: this.$t("emergency degree") },
              { key: "delivery_man", label: this.$t("delivery man") },
              { key: "storekeeper", label: this.$t("storekeeper") },
              { key: "seller_agent", label: this.$t("seller") },
            ],
    };
  },
  methods: {
    async loadDeliveryMan() {
      try {
        const res = await instance.get("/parameters/delivery-users/");
        this.deliveryMen = res.data;
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async confirmOrderLineConsultation() {
      if (!this.selectedShippingDate || !this.selectedShippingType) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("Delivery date and type are required"),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        });
        return;
      }

      const [shipping_date, shipping_time] =
        this.selectedShippingDate.split(" ");

      await instance.post(`/orders/order-lines/${this.selectedOrderLine.id}`, {
        shipping_date: shipping_date,
        shipping_type: this.selectedShippingType.id,
      });
      /*await instance.put(
        `/orders/order-lines/${this.selectedOrderLine.id}/storehead/Update/`,
        {
          delivery_man: this.selectedDeliveryMan.id,
          state: "verified",
        }
      );*/
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Success"),
          text: this.$t("Order line has been modified"),
          icon: "CheckIcon",
          variant: "success",
        },
      });
      this.consulteOrder(this.order);
    },
    onConsulteOrderModalHidden() {
      this.orderLines = [];
    },
    onDeliveryInfoModalHidden() {
      this.selectedShippingDate = null;
      this.selectedShippingType = null;
    },

    async onRowSelected(orderLine) {
      await this.fetchDeliveryMethods();
      this.selectedOrderLine = orderLine;
      this.selectedShippingDate = orderLine.shipping_date;
      this.selectedShippingType = orderLine.shipping_type_id;
      this.selectedDeliveryMan = orderLine.delivery_agent_id;
      this.$refs["delivery-info"].show();
    },
    async fetchDeliveryMethods() {
      try {
        const res = await instance.get("/parameters/shipping-types/");
        this.deliveryTypes = res.data;
      } catch (err) {
        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    onCancelOrderLineHidden() {
      this.orderLines = [];
    },
    confirmCancelingOrderLine(bvModalEvt) {
      bvModalEvt.preventDefault();
    },
    async consulteOrder(order) {
      this.orderDate = order.date;
      this.client = order.client.name;
      if (order.source != null) {
        this.orderSource = order.source.name;
      }
      if (order.operator != null) {
        this.operator = order.operator.name;
      }
      try {
        this.orderLines = [];
        this.order = order;

        const res = await instance.get(`/orders/${order.id}/order-lines/`, {
          params: { state_id: this.stateId, role: "operation_managment" },
        });
        res.data.forEach((orderLine) => {
          const {
            product_shortcut,
          } = orderLine.product_combination;
          const {
            state,
            ordered_qty,
            piece,
            available_qty,
            available_piece,
            shipping_type,
            shipping_date,
            storekeeper_agent,
            delivery_agent,
            order_line_type,
            emergency_degree,
            client_annexe,
            seller_agent,
			preparations,
          } = orderLine;
          if(emergency_degree!=null){
            var emergency_degree_name = emergency_degree.name;
          }else{
            var emergency_degree_name = null;
          }
          if(client_annexe!=null){
            var client_annexe_name = client_annexe.name;
          }else{
            var client_annexe_name = null;
          }
		  if(preparations!=null){
            var preparation_name = preparations.name;
          }else{
            var preparation_name = null;
          }
          if (order_line_type === true) {
            this.orderLines.push(
              this.stateId === "1"
                ? {
                    id: orderLine.id,
                    product: product_shortcut,
					preparations: preparation_name,
                    state: state.name,
                    ordered_qty: ordered_qty,
                    available_qty: available_qty,
                    piece: piece,
                    available_piece: available_piece,
                    shipping_type: shipping_type.name,
                    shipping_type_id: shipping_type,
                    shipping_date: `${shipping_date}`,
                    delivery_man: delivery_agent?.name,
                    type: this.$t("Street sale"),
                    emergency_degree : emergency_degree_name,
                    client_annexe : client_annexe_name,
                  }
                : {
                    id: orderLine.id,
                    product: product_shortcut,
					preparations: preparation_name,
                    state: state.name,
                    ordered_qty: ordered_qty,
                    available_qty: available_qty,
                    piece: piece,
                    available_piece: available_piece,
                    shipping_type: shipping_type.name,
                    shipping_date: `${shipping_date}`,
                    shipping_type_id: shipping_type,
                    storekeeper: storekeeper_agent?.name,
                    delivery_man: delivery_agent?.name,
                    seller_agent:seller_agent?.name,
                    type: this.$t("Street sale"),
                    emergency_degree : emergency_degree_name,
                    client_annexe : client_annexe_name,
                  }
            );
          } else {
            this.orderLines.push(
              this.stateId === "1"
                ? {
                    id: orderLine.id,
                    product: product_shortcut,
					preparations: preparation_name,
                    state: state.name,
                    ordered_qty: ordered_qty,
                    available_qty: available_qty,
                    piece: piece,
                    available_piece: available_piece,
                    shipping_type: shipping_type.name,
                    shipping_type_id: shipping_type,
                    shipping_date: `${shipping_date}`,
                    delivery_man: delivery_agent?.name,
                    type: this.$t("Store sale"),
                    emergency_degree : emergency_degree_name,
                    client_annexe : client_annexe_name,
                  }
                : {
                    id: orderLine.id,
                    product: product_shortcut,
					preparations: preparation_name,
                    state: state.name,
                    ordered_qty: ordered_qty,
                    available_qty: available_qty,
                    piece: piece,
                    available_piece: available_piece,
                    shipping_type: shipping_type.name,
                    shipping_date: `${shipping_date}`,
                    shipping_type_id: shipping_type,
                    storekeeper: storekeeper_agent?.name,
                    delivery_man: delivery_agent?.name,
                    seller_agent:seller_agent?.name,
                    type: this.$t("Store sale"),
                    emergency_degree : emergency_degree_name,
                    client_annexe : client_annexe_name,
                  }
            );
          }
        });

        this.$refs[`consulte-order-${order.id}`].show();
      } catch (err) {

        const error = err.response
          ? Object.values(err.response.data)[0][0]
          : err.message;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: error ?? this.$t("An error has occurred. Please try again"),
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
  },
};
</script>
<style>
#baniere_gauche {
  height: 100%;
  float: left;
  top: 0;
  left: 0;
}

#baniere_droite {
  position: relative;
  height: 100%;
  float: right;
  top: 0;
  right: 0;
}
td.color {
  background-color: #f3f2f7;
}
table#scrolle-table {
  white-space: nowrap;
}
</style>
